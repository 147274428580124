import React, { Component , Fragment } from "react";
import Header from "../component/Header";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/FooterTwo";
import Helmet from "../component/Helmet";
import { Link } from 'react-router-dom';


class Store extends Component{
    render(){
        return(
            <Fragment> 


                <Helmet pageTitle="Store" />
                <Header homeLink="/"/>

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="7">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="rn-page-title text-center pt--100">
                                        <h2 className="title textWhite">Store</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrump Area */}
            

                    <div className="ptb--120  bg_color--1">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className={`col-lg-5 order-lg-1 order-1`}>
                                    <img className="w-110" src='/assets/images/shirt.png' alt='shirt'/>
                                </div>
                                <div className={`col-lg-7 order-lg-2 order-2`}>
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Raven Labs TShirt</h2>
                                            <p className="description">Black Raven Labs Tshirt for $15. Shipping is included but only available in the United States. Currently we only accept Bitcoin payments on either on chain or lightning network. We currently run our own Bitcoin Node and Lightning Node, along with BTCPayServer on a Raspberry Pi to make this purchase possible.</p>
                                        </div>

                                        <div className="pt--20">
                                            <Link className=" rn-button-style--2" to='/store/tshirt' ><span>Buy - $15 BTC</span></Link>
                                        </div>                                    
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>

            
                <FooterTwo />
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>

            
            </Fragment>
        )
    }
}

export default Store;