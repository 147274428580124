import React, { Component , Fragment } from "react";
import Header from "../component/Header";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/FooterTwo";
import Helmet from "../component/Helmet";
import emailjs from 'emailjs-com';

import { Link } from 'react-router-dom';


class PurchaseComplete extends Component{

    componentDidMount(){
        if (this.props.match.params) {
            try {
              console.log(this.props.match.params.name)

              const email = {
                full_name: this.props.match.params.full_name,
                email_address:  this.props.match.params.email_address,
                phone_number: this.props.match.params.phone_number,
                address: this.props.match.params.address,
                city: this.props.match.params.city,
                state: this.props.match.params.state,
                zip_code: this.props.match.params.zip_code
              }

              emailjs
                .send(
                    'store_purchase', 
                    'template_pn3eko2', 
                    email, 
                    'jL1sj-VsQ2YChI578'
                )
                .then((result) => {
                    console.log(result.text);
                    }, 
                    (error) => {
                        console.log(error.text);
                    }
                );
            } catch (err) {
                console.log("Error Getting Props ", err)
              }
            }
          }
    
    render(){
        return(
            <Fragment> 


                <Helmet pageTitle="Thank You" />
                <Header homeLink="/"/>

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="7">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="rn-page-title text-center pt--100">
                                        <h2 className="title textWhite">Thank You</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrump Area */}

                    <div className="ptb--120  bg_color--1">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className={`col-lg-5 order-lg-2 order-1`}>
                                    <img className="w-110" src='/assets/images/shirt.png' alt='shirt'/>
                                </div>
                                <div className={`col-lg-7 order-lg-1 order-2`}>
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Purchase Complete</h2>
                                            <p className="description">Thank you for purchasing a Raven Labs T-shirt! Your order should arrive in 1-3 weeks. A team member will contact you if we need any further information.</p>
                                        </div>

                                        <div className="pt--20">
                                            <Link className=" rn-button-style--2" to='/' ><span>Finish</span></Link>
                                        </div>                                    
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
            

            
                <FooterTwo />
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>

            
            </Fragment>
        )
    }
}

export default PurchaseComplete;