import React, { Component } from "react";
import PageHelmet from "../component/Helmet";
import { FiClock, FiUser } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/Header";
import Footer from "../component/Footer";

class IotBlogPart2 extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="Focusing on the Data: IoT Series Part 2 | Blog"
          metaDescription="Dive deeper into our IoT series with Part 2, where we decode GPS data from our CubeCell board and integrate with AWS for storage. Learn how to transform raw bytes into actionable insights and prepare for data visualization."
          metaKeywords={[
            "IoT",
            "Data Decoding",
            "AWS Integration",
            "LoRaWAN",
            "GPS Data",
            "Serverless Architecture",
            "Asset Tracking",
            "CubeCell",
            "ChirpStack",
            "AWS DynamoDB",
            "AWS SNS",
            "AWS Lambda",
            "AWS IAM",
          ]}
          canonicalUrl="https://www.ravenlabsnh.com/blog/iot-asset-tracker-part-2"
          ogTitle="Focusing on the Data: IoT Series Part 2"
          ogType="article"
          ogImage="https://www.ravenlabsnh.com/assets/images/blog/CubeCellFinalDevice.webp"
          ogUrl="https://www.ravenlabsnh.com/blog/iot-asset-tracker-part-2"
          twitterCard="summary_large_image"
          twitterTitle="Focusing on the Data: IoT Series Part 2 | Blog"
          twitterDescription="In Part 2 of our IoT series, we decode GPS data for AWS storage, setting the groundwork for the visualization phase. Follow our guide to make your IoT data work for you."
          twitterImage="https://www.ravenlabsnh.com/assets/images/blog/CubeCellFinalDevice.webp"
        />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--29 "
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title textWhite">
                    Build an IoT Asset Tracker <br />
                  </h2>
                  <h3 className="title textWhite">
                    {" "}
                    Step-by-Step Guide: Part 2
                  </h3>

                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      February, 2024
                    </li>
                    <li>
                      <FiUser />
                      Jeff Nelson
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--80 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <h3>Part 2: Focusing on the Data</h3>
                    <p>
                      Welcome back to the second installment of our IoT series
                      where we are building our own Asset Tracker. Previously,
                      we configured our CubeCell development board to send
                      packets over the LoRaWAN network that contain GPS data and
                      configured our devices on the{" "}
                      <a href="https://console.helium-iot.xyz/front/">
                        {" "}
                        Helium-IoT ChirpStack LNS
                      </a>{" "}
                      to receive these packets. Now, it's time to focus on
                      decoding and further processing of this incoming data. In
                      this part, we'll develop a decoder to transform the raw
                      bytes into a JSON object, making it ready for use in
                      various integrations. Our main focus will be on
                      integrating with AWS, where we'll store the decoded GPS
                      data in a database. In the last part of this series, we
                      plan to introduce a simple web application to visualize
                      this data on a map. Let's dive in.
                    </p>

                    <h4>Unpacking the Data</h4>

                    <div className="thumbnail mt--20 pb--20">
                      <img
                        src="/assets/images/blog/ChirpStackCodec.webp"
                        alt="Creating a ChirpStack Codec"
                      />
                    </div>

                    <p>
                      The first step involves creating a decoder, also known as
                      a codec in ChirpStack. We begin by accessing our
                      <a href="https://console.helium-iot.xyz/front/">
                        {" "}
                        ChirpStack Console
                      </a>{" "}
                      on Helium-IoT LNS and navigating to the “CubeCell AB02S”
                      device profile we previously set up in the last tutorial.
                      Within this profile, we need to navigate to the Codec
                      section on the top menu. Here, we select “Javascript
                      functions” from the Payload codec dropdown menu. A
                      template decoder is now displayed and we will be focusing
                      solely on decodeUplink since our current application only
                      involves sending uplink messages from our GPS tracker. To
                      build this decoder, we'll revisit the source code of our
                      GPS tracker to understand exactly how it packages data for
                      transmission.{" "}
                    </p>

                    <pre>
                      <code>
                        {`//Build Payload
 unsigned char *puc;
 appDataSize = 0;

 puc = (unsigned char *)(&lat);
 appData[appDataSize++] = puc[3];
 appData[appDataSize++] = puc[2];
 appData[appDataSize++] = puc[1];
 appData[appDataSize++] = puc[0];

 puc = (unsigned char *)(&lon);
 appData[appDataSize++] = puc[3];
 appData[appDataSize++] = puc[2];
 appData[appDataSize++] = puc[1];
 appData[appDataSize++] = puc[0];
`}
                      </code>
                    </pre>

                    <p>
                      In examining the payload construction closely, we can see
                      that the first 4 bytes is the latitude and the next 4
                      bytes is the longitude. In our Codec function, we will
                      take this raw string of bytes and reconstruct our data:
                    </p>

                    <pre>
                      <code>
                        {`// Decode latitude
var lat = (bytes[0] << 24) | (bytes[1] << 16) | (bytes[2] << 8) | bytes[3];
lat = lat / 10000000; // Adjusting for latitude scaling

// Decode longitude
var lon = (bytes[4] << 24) | (bytes[5] << 16) | (bytes[6] << 8) | bytes[7];
lon = lon / 10000000; // Adjusting for longitude scaling

`}
                      </code>
                    </pre>
                    <p>
                      This approach enables us to convert the raw bytes into
                      useful GPS coordinates that we'll soon forward to our
                      integrations. A deeper inspection of the source code
                      uncovers the structure for the rest of the packet,
                      providing clear guidance on extracting further GPS and
                      device data. Although we don't have an immediate use for
                      this additional data, by fully populating the Decoder with
                      the entire packet, we ensure the flexibility to seamlessly
                      incorporate this data into any future integration as
                      needed. By applying this method, here’s how we finalize
                      our decoder function:
                    </p>

                    <pre>
                      <code>
                        {`function decodeUplink(input) {
  var bytes = input.bytes;
  var lat = (bytes[0] << 24) | (bytes[1] << 16) | (bytes[2] << 8) | bytes[3];
  lat = lat / 10000000; // Correcting latitude scale

  var lon = (bytes[4] << 24) | (bytes[5] << 16) | (bytes[6] << 8) | bytes[7];
  lon = lon / 10000000; // Correcting longitude scale

  return {
    data: {
      lat: lat,
      lon: lon,
      speed: bytes[8],
      hdop: bytes[9] / 10.0,
      batteryVoltage: bytes[10] * 20
    }
  };
}
`}
                      </code>
                    </pre>

                    <p>
                      This simple yet effective codec transforms the bytes from
                      our GPS device into a JSON object, neatly organizing our
                      data into a usable format that is easy to work with for
                      any of our integrations. Once this codec is implemented,
                      we can confirm its working correctly through the{" "}
                      <strong>Measurements</strong>
                      tab in ChirpStack.
                    </p>

                    <h4>Verifying Decoded Data</h4>

                    <div className="thumbnail mt--20 pb--20">
                      <img
                        src="/assets/images/blog/ChirpStackMeasurements.webp"
                        alt="Creating a ChirpStack Measurement"
                      />
                    </div>

                    <p>
                      After completing the setup of your codec function, lets
                      navigate ove to the <strong>Measurements</strong> tab in
                      the <strong>Device Profiles </strong>
                      section. In this section, if the option to "Automatically
                      detect measurement keys" is enabled, much of this might
                      already be configured for you. However, it’s wise to
                      verify the setup as follows:
                    </p>
                    <p>
                      Begin by selecting <strong>Add measurement</strong>. Here,
                      you’ll assign a measurement key that corresponds to the
                      JSON key of the data we just created in the code— starting
                      with "lat" for latitude. Choose <strong>String</strong> as
                      the measurement kind to simply display the coordinates,
                      and label the measurement "latitude". Repeat the process
                      for longitude, ensuring both are submitted correctly.
                    </p>
                    <p>
                      With these measurements configured and saved, activate
                      your GPS tracker to acquire a signal—this might
                      necessitate stepping outdoors. Upon your device connecting
                      to the network and transmitting a GPS packet, navigate to
                      the "Asset Tracker" application within the Applications
                      section of ChirpStack. Select your device using the DevEUI
                      to view its data. Within the{" "}
                      <strong>Device metrics</strong> tab above the displayed
                      graphs, you'll find the newly established device
                      measurements. Seeing the accurate GPS coordinates here
                      signifies successful data decoding.
                    </p>

                    <div className="thumbnail mt--20 pb--20">
                      <img
                        src="/assets/images/blog/ChirpStackDeviceMetrics.webp"
                        alt="Creating a ChirpStack Measurement"
                      />
                    </div>

                    <p>
                      Now, with our data successfully decoded in ChirpStack, we
                      can begin integrating this data with AWS.
                    </p>

                    <h3>Setting Up AWS Services</h3>

                    <p>
                      AWS has a messaging service known as SNS (Simple
                      Notification Service), designed to facilitate
                      communication between various platforms and services. It
                      operates on a publisher/subscriber model, where messages
                      are sent to a centralized topic—similar to a mailbox—from
                      which subscribed services can retrieve messages. In our
                      case, ChirpStack functions as the publisher, sending our
                      decoded data to a topic, while an AWS Lambda function acts
                      as the subscriber, processing this data further.
                    </p>

                    <div className="blog-single-list-wrapper-left d-flex flex-wrap">
                      <div className="thumbnail vertical">
                        <img
                          src="/assets/images/blog/ServerlessArchitecture.webp"
                          alt="Chirpstack - Create Application"
                        />
                      </div>
                      <div className="content">
                        <p>
                          To effectively use this architecture for our IoT Asset
                          Tracker, we'll undertake four key actions within AWS:
                        </p>
                        <ul className="list-style mt--40">
                          <li>
                            <strong>Create an IAM User:</strong> This step
                            involves setting up a new IAM user in AWS, providing
                            us with the necessary credentials to establish a
                            secure connection between ChirpStack and AWS
                            services.
                          </li>
                          <li>
                            <strong>Set Up DynamoDB:</strong> We'll create a
                            DynamoDB table to serve as the storage solution for
                            our GPS data, ensuring durability and scalability.
                          </li>
                          <li>
                            <strong>Establish an SNS Topic:</strong> By creating
                            a topic in Amazon SNS, we establish a conduit
                            through which our data can be published from
                            ChirpStack to AWS, ensuring that subscribed services
                            are promptly notified of new messages.
                          </li>
                          <li>
                            <strong>Deploy a Lambda Function:</strong> Writing a
                            Python script for a Lambda function allows us to
                            automatically process incoming GPS data from our SNS
                            topic and store it in DynamoDB. This serverless
                            approach is not only cost-effective for small-scale
                            operations but also simplifies scaling as our
                            project grows.
                          </li>
                        </ul>
                        <p>
                          These steps lay the foundation for a seamless flow of
                          data from our IoT devices through AWS, enabling data
                          handling and storage capabilities for our asset
                          tracking application.
                        </p>
                      </div>
                    </div>

                    <h4>AWS IAM</h4>

                    <p>
                      Starting with creating a new IAM (Identity and Access
                      Management) user is our initial step towards integrating
                      our system with AWS. Navigate to the IAM section within
                      AWS and click the <strong>Create User</strong> button.
                      We'll name this user "IoTUser" and ensure that the option
                      to provide user access to the AWS Management Console
                      remains unchecked for security purposes.
                    </p>

                    <div className="thumbnail mt--20 pb--20">
                      <img
                        src="/assets/images/blog/AwsIam.webp"
                        alt="Creating a new IAM user in AWS"
                      />
                    </div>

                    <p>
                      The next step is important—assigning the correct
                      permissions. Our "IoTUser" will primarily interact with
                      Amazon SNS (Simple Notification Service) and Amazon
                      DynanoDB, necessitating specific access rights. To
                      accomplish this, we select “Attach policies directly” and
                      search for “AmazonSNSFullAccess” and
                      "AWSLambdaInvocation-DynamoDB" which we then attach to our
                      user. Following this, we proceed to finalize the creation
                      of the user.
                    </p>
                    <p>
                      Upon the user's creation, locate and select "IoTUser" from
                      the user list to access their profile. Within the profile,
                      navigate to the <strong>Security Credentials</strong> tab
                      and initiate the creation of an access key by selecting
                      “Create access key.” This process is straightforward, and
                      though it prompts for selecting a service type or adding
                      tags, you can proceed without making selections here.
                    </p>
                    <p>
                      The creation of these access keys is a pivotal moment—they
                      are your credentials for connecting ChirpStack to AWS.
                      Ensure you securely copy both the access key and the
                      secret access key; these are key for our upcoming
                      configurations. It's important to note, once you navigate
                      away from this page, the keys are no longer retrievable in
                      the same manner, and you would need to generate new ones
                      if they're lost.
                    </p>

                    <h4>AWS SNS</h4>

                    <p>
                      Next, let's shift our focus to setting up Amazon SNS
                      (Simple Notification Service) for message publishing. Head
                      over to the Amazon SNS section within AWS. Here, find and
                      select "Topics" from the sidebar, then proceed to click
                      <strong>Create Topic</strong>. We'll name our new topic
                      "HeliumMapper". We can keep all the other default
                      settings. Once the topic is successfully created, it will
                      appear in the topic list, identifiable by its ARN (Amazon
                      Resource Name). It's important to copy this ARN, as we'll
                      require it shortly for integrating with ChirpStack.
                    </p>

                    <div className="thumbnail mt--20 pb--20">
                      <img
                        src="/assets/images/blog/AwsSns.webp"
                        alt="Creating a SNS topic in AWS"
                      />
                    </div>

                    <p>
                      With our topic ready, our next move involves configuring
                      the AWS SNS integration on ChirpStack. However, before
                      transitioning back to ChirpStack, take a moment to note
                      down the AWS region your SNS topic resides in. This detail
                      is typically visible in the AWS console URL, appearing as
                      a segment like “us-east-1” in
                      “us-east-1.console.aws.amazon.com”. This regional
                      information plays a vital role in the forthcoming steps of
                      our setup process.
                    </p>

                    <h4> Chirpstack </h4>

                    <p>
                      Within ChirpStack, under the "Asset Tracker" application,
                      proceed to the <strong>Integrations</strong> tab to set up
                      our connection with AWS SNS. Look for and click on the "+"
                      button to add a new integration, selecting AWS SNS from
                      the options available.
                    </p>

                    <div className="thumbnail mt--20 pb--20">
                      <img
                        src="/assets/images/blog/ChirpStackIntegration.webp"
                        alt="Creating a SNS topic in AWS"
                      />
                    </div>

                    <p>
                      At this point, you'll input the configuration details:
                    </p>

                    <ul className="list-style">
                      <li>
                        <strong>Payload Encoding:</strong> Choose JSON as the
                        format for the data being sent. This ensures that the
                        data is structured in a universally recognizable format,
                        making processing and interpretation straightforward on
                        the AWS side.
                      </li>
                      <li>
                        <strong>AWS Region:</strong> Enter the AWS region you
                        noted earlier, such as "us-east-1". This specifies the
                        geographical location of your SNS topic and is critical
                        for ensuring that ChirpStack communicates with the
                        correct AWS services.
                      </li>
                      <li>
                        <strong>
                          AWS Access Key ID and Secret Access Key:
                        </strong>{" "}
                        These are the credentials generated for the "IoTUser"
                        IAM user. They authenticate the requests sent from
                        ChirpStack to AWS, securing the connection between the
                        two services.
                      </li>
                      <li>
                        <strong>AWS SNS Topic ARN:</strong> Paste the ARN of the
                        "HeliumMapper" topic you created in AWS SNS. This ARN
                        uniquely identifies the topic within AWS, directing
                        ChirpStack where to publish the data.
                      </li>
                    </ul>
                    <p>
                      With all settings correctly entered, submit the form. This
                      action finalizes the integration, enabling ChirpStack to
                      start publishing data to AWS SNS based on the
                      configurations you've just applied.{" "}
                    </p>

                    <h4> AWS DynamoDB </h4>

                    <p>
                      Within AWS, our next step involves setting up DynamoDB to
                      serve as a data store for our GPS data. Start by
                      navigating to the DynamoDB service within the AWS console.
                      Once there, locate and click on the{" "}
                      <strong>Tables</strong> option in the sidebar, then
                      proceed to select <strong>Create table</strong>.
                    </p>

                    <div className="thumbnail mt--20 pb--20">
                      <img
                        src="/assets/images/blog/AwsDynamoDB.webp"
                        alt="Creating a SNS topic in AWS"
                      />
                    </div>

                    <p>
                      For the table name, enter "AssetTracker" to clearly
                      identify its purpose. The primary key should be structured
                      with "device_id" as the partition key, designated as a
                      string. This key uniquely identifies each device.
                      Additionally, introduce a sort key named "utc_time," also
                      a string, enabling us to organize data chronologically.
                      This setup allows our web application to efficiently
                      filter and retrieve the most recent GPS data for any given
                      device.
                    </p>

                    <p>
                      When configuring the rest of the DB table settings, we
                      will proceed with the default options provided by AWS.
                      After reviewing your settings, click the{" "}
                      <strong>Create table </strong>
                      button to finalize the creation of your DynamoDB table.
                      This table will now act as the central storage point for
                      the GPS data collected by our IoT Asset Tracker.
                    </p>

                    <h4> AWS Lambda Function </h4>

                    <p>
                      The final step in AWS involves setting up a Lambda
                      function that acts on the incoming SNS messages by
                      extracting the JSON data and storing it in our DynamoDB
                      table. Here’s how to proceed:
                    </p>

                    <h5>Creating the Lambda Function:</h5>

                    <ul className="list-style">
                      <li>
                        Navigate to the AWS Lambda section and choose{" "}
                        <strong>Create function</strong>
                      </li>
                      <li>
                        Opt for “Author from scratch,” name your function
                        “AssetTrackerUpdateDB” and select the latest Python
                        runtime version.
                      </li>
                      <li>
                        Click <strong>Create function</strong> to proceed.
                      </li>
                    </ul>

                    <h5>Configuring the Function:</h5>

                    <div className="thumbnail mt--20 pb--20">
                      <img
                        src="/assets/images/blog/AwsLambda.webp"
                        alt="Configuring AWS Lambda SNS Trigger"
                      />
                    </div>

                    <ul className="list-style">
                      <li>
                        With the function created, access its settings to add a
                        trigger.
                      </li>
                      <li>
                        In the “Add Trigger” options, choose “SNS” as the
                        trigger source and select the “HeliumMapper” SNS topic
                        you previously created.
                      </li>
                      <li>
                        Confirm by clicking the "Add" button. This setup ensures
                        the Lambda function is invoked each time a message is
                        published to the “HeliumMapper” topic.
                      </li>
                    </ul>

                    <h5>Implementing the Code:</h5>

                    <ul className="list-style">
                      <li>
                        Return to the function settings and navigate to the code
                        section.
                      </li>
                      <li>
                        Here, you’ll implement the logic to process the SNS
                        message, extracting the Device ID, GPS coordinates, and
                        time, and then writing this data to your DynamoDB table.{" "}
                      </li>
                    </ul>

                    <p>
                      The Lambda function's logic involves parsing the SNS
                      message for relevant data and using AWS SDK for Python
                      (Boto3) to interact with DynamoDB. Ensure you replace
                      placeholders like AWS_ACCESS_KEY and AWS_SECRET_ACCESS_KEY
                      with actual credentials from the IAM user you created
                      earlier.
                    </p>

                    <pre>
                      <code>
                        {`import json
import boto3

def lambda_handler(event, context):
    session = boto3.client( 
        "dynamodb",
        aws_access_key_id="<ACCESS ID>",
        aws_secret_access_key="<ACCESS KEY>",
    )
    
    if event['Records'] and event['Records'][0]:
      # First, get the Message field from the SNS event
      message_str = event['Records'][0]['Sns']['Message']
      
      # Deserialize the message string into a Python dictionary
      message_dict = json.loads(message_str)
            
      device_id = message_dict['deviceInfo']['devEui']
      unix_timestamp =  message_dict['object']['time']
      lat = message_dict['object']['lat']
      lon = message_dict['object']['lon']

      session.put_item(TableName='AssetTracker', Item={
              'device_id': {'S' : str(device_id)},
              'utc_time': {'S' : str(unix_timestamp)},
              'lat': {'S' : str(lat)},
              'long': {'S' : str(lon)}
          })
`}
                      </code>
                    </pre>

                    <p>
                      With the Python code integrated into your function and set
                      up with the appropriate keys, this Lambda function bridges
                      the gap between receiving GPS data and storing it
                      efficiently in DynamoDB, setting the stage for its
                      visualization in the upcoming part of your project.
                    </p>

                    <h4>Verify its working correctly</h4>
                    <p>
                      Once you've finalized your AWS Lambda function with the
                      necessary code, remember to save your changes and then
                      click the <strong>Deploy</strong> button to ensure your
                      modifications are applied. With this action, your setup in
                      AWS should now be fully operational, allowing data from
                      your IoT tracker to be seamlessly captured and stored in
                      the DynamoDB database.
                    </p>
                    <p>
                      To verify that everything is functioning as intended, it's
                      a good practice to revisit the DynamoDB service within
                      AWS. From the left-hand menu, navigate to the{" "}
                      <strong>Tables </strong>
                      section and then click on <strong>Explore Items</strong>.
                      Here, select the "AssetTracker" table you created earlier.
                      If the setup has been successful, you'll begin to notice
                      entries populating in this table, indicating that the data
                      from your IoT tracker is being accurately recorded.
                    </p>
                    <p>
                      Should you encounter any issues or not see the expected
                      data entries, it's crucial to revisit each step of your
                      setup process to identify and correct any discrepancies.
                      This includes double-checking the Lambda function's code,
                      ensuring the correct IAM permissions are in place, and
                      verifying that your SNS topic and DynamoDB table are
                      correctly linked in your configurations.
                    </p>

                    <h4>Wrapping up</h4>
                    <p>
                      With our data now successfully transmitted over the Helium
                      LoRaWan network, decoded, and integrated into AWS, we've
                      laid a solid foundation for our IoT Asset Tracker. The
                      data, once a mere stream of bytes, has been transformed
                      and stored in DynamoDB, ready for long-term retention.
                      This preparation paves the way for the next and final
                      phase of our journey: the development of a simple web
                      application designed to visualize this data.
                    </p>
                    <p>
                      In our upcoming section, we'll focus on retrieving the
                      stored data and presenting the coordinates on a map. This
                      visualization will not only display the current location
                      but also allow us to explore the last 10 coordinates
                      captured by our asset tracker. Such a feature offers a
                      dynamic view into the movement history of our tracked
                      asset, providing insights and enhancing the utility of our
                      IoT solution.
                    </p>
                    <p>
                      Stay tuned for this exciting culmination of our series,
                      where we bring together all the components to create a
                      comprehensive and interactive experience with our IoT
                      Asset Tracker.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default IotBlogPart2;
