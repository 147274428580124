import React, { Component , Fragment } from "react";
import Header from "../component/Header";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/FooterTwo";
import Helmet from "../component/Helmet";
import ContactForm from "../component/ContactForm";

class Shirt extends Component{
    render(){
        return(
            <Fragment> 


                <Helmet pageTitle="Purchase Tshirt" />
                <Header homeLink="/"/>

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="7">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="rn-page-title text-center pt--100">
                                        <h2 className="title textWhite">Purchase</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrump Area */}
            

                    <div className="ptb--120 contact-form--1">
                        <div className="container">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-6 order-2 order-lg-1">
                                    <div className="section-title text-left mb--50">
                                        <h2 className="title">Raven Labs T-shirt</h2>
                                        <p className="description">Enter your shipping information below. Shipping only available in the United States. Please allow 1-3 weeks for delivery.</p>
                                    </div>
                                    <div className="form-wrapper">
                                        <ContactForm />
                                    </div>
                                </div>
                                <div className="col-lg-6 order-1 order-lg-2">
                                    <div className="mb_md--30 mb_sm--30">
                                        <img src='/assets/images/shirt.png' alt='shirt'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            
                <FooterTwo />
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>

            
            </Fragment>
        )
    }
}

export default Shirt;