import React , {useState} from 'react';

function ContactForm({props}) {
   const [fullName, setFullName] = useState("");
   const [emailAddress, setEmailAddress] = useState("");
   const [phoneNumber, setPhoneNumber] = useState("");
   const [address, setAddress] = useState("");
   const [city, setCity] = useState("");
   const [state, setState] = useState("");
   const [zipcode, setZipcdoe] = useState("");

    return (
        <form method="POST" action="https://ravenlabs.duckdns.org:58131/apps/2Q3hq8vejaGdodbxKT4jAm2uBsvs/pos">

            <select>
                <option value="large">Large</option>
                <option value="medium">Medium</option>
                <option value="small">Small</option>
            </select>

            <input 
            type="text"
            name="full_name"
            value={fullName}
            onChange={(e)=>{setFullName(e.target.value);}}
            placeholder="Your Full Name"
            required
            />

            <input 
            type="email"
            name="email"
            value={emailAddress}
            onChange={(e)=>{setEmailAddress(e.target.value);}}
            placeholder="Your Email"
            required
            />

            <input 
            type="text"
            name="phone_number"
            value={phoneNumber}
            onChange={(e)=>{setPhoneNumber(e.target.value);}}
            placeholder="Phone Number"
            required
            />

            <input 
            type="text"
            name="address"
            value={address}
            onChange={(e)=>{setAddress(e.target.value);}}
            placeholder="Address"
            required
            />

            <input 
            type="text"
            name="city"
            value={city}
            onChange={(e)=>{setCity(e.target.value);}}
            placeholder="City"
            required
            />

            <input 
            type="text"
            name="state"
            value={state}
            onChange={(e)=>{setState(e.target.value);}}
            placeholder="State"
            required
            />
        
            <input 
            type="text"
            name="zip_code"
            value={zipcode}
            onChange={(e)=>{setZipcdoe(e.target.value);}}
            placeholder="Zip Code"
            required
            />

            <input type="hidden" name="notificationUrl" value={`https://staging.ravenlabsnh.com/store/checkout/${fullName}/${emailAddress}/${phoneNumber}/${address}/${city}/${state}/${zipcode}`}/>
            <input type="hidden" name="redirectUrl"  value={`https://staging.ravenlabsnh.com/store/checkout/${fullName}/${emailAddress}/${phoneNumber}/${address}/${city}/${state}/${zipcode}`}/>    

            <button className="rn-button-style--2 btn-solid" type="submit" name="choiceKey" value="tshirt"  id="mc-embedded-subscribe">Pay with Bitcoin</button>
        </form>

        
    )
}
export default ContactForm;
