import React, { Component } from "react";
import Helmet from "../component/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/Header";
import Footer from "../component/Footer";


class WebDevelopment extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
            <Helmet pageTitle="Web Development" />
            <Header homeLink="/"/>

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="7">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title textWhite">Web Development</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/webDevelopment2.avif" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Welcome to our web development services, where we specialize in crafting exceptional online experiences. With expertise in ECommerce platforms like Shopify and WooCommerce, we create robust online stores that drive sales and engage customers. We excel in tracking analytics using Google Tag Manager, providing valuable insights for optimization and data-driven decision-making.</p>
                                                    <p>Our knowledge extends beyond ECommerce platforms. We have expertise in infrastructure providers like AWS, Google Cloud, and Microsoft Azure, enabling us to design scalable and secure web solutions. From self-hosted setups to reliable hosting solutions like Kinsta, we offer flexible hosting environments tailored to your needs.</p>
                                                    <p>We have deep proficiency in various platforms, including React for dynamic web applications, WordPress for content management, and headless CMS for scalability. Our team can create highly customized solutions that align with your business goals. Additionally, we integrate attribution software such as Hyros and Fairing, empowering you to gain insights into your marketing efforts and optimize your strategies for maximum ROI.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Partner with us to unlock the true potential of your online presence. We bring together expertise in ECommerce, infrastructure, hosting environments, platforms, and attribution software. Our tailored solutions drive success in the digital landscape, delivering exceptional user experiences and empowering data-backed decision-making.</p>
                                                    <h4 className="title">Technologies</h4>
                                                    <ul className="liststyle">
                                                        <li>Platforms: Wordpress, WooCommerce, Shopify, BigCommerce</li>
                                                        <li>Integrations: GTM, GA4, Hyros, Fairing</li>
                                                        <li>Hosting Providers: AWS, Google Cloud, Microsoft Azure, Kinsta, Netlify </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                            <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/webDevelopment3.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default WebDevelopment;