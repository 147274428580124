import React, { Component , Fragment } from "react";
import IndividualProject from "../component/IndividualProject"


class Monochromator extends Component{
    render(){
        let title = 'Motorized Monochromator',
        pageTitle = "Motorized Monochromator",
        description = "Raven Labs delivered a software suite to a leading optical client. The software suite allows a customer to precisely control a motorized monochromator, a device which separates light into its constituent wavelengths. Users can program custom scanning sequences, jog, and move to absolute wavelengths.  ",
        video = '',
        image = '/assets/images/Monochromator.png',
        alt = "Motorized Monochromator Software",
        teamMembers =  "Jeff Nelson",
        client = "Optometrics",
        timespan = "2021-2022",
        hardware = [
            {
                name: "Microcontroller",
                model: "STM-F4REE"
            },
            {
                name: "Single Board Computer",
                model: "Raspberry Pi 4B"
            },
            {
                name: "Touch Screen",
                model: 'UPerfect 10.1" IPS 1920X1200 Display'
            }
        ];


        return(
            <Fragment> 
                <IndividualProject title={title} pageTitle={pageTitle} description={description} image={image} alt={alt} teamMembers={teamMembers} client={client} timespan={timespan} hardware={hardware} video={video}/>
            </Fragment>
        )
    }
}

export default Monochromator;