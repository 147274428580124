import React, { Component , Fragment } from "react";
import IndividualProject from "../component/IndividualProject"


class Dyno extends Component{
    render(){
        let title = 'Dyno',
        pageTitle = "Dynamometer",
        description = "Introducing The Raven Labs Dynamometer.  Custom designed for gearbox testing on a leading company’s medical device. Our dynamometer provides a comprehensive evaluation of the gearbox's capabilities, allowing manufacturers to analyze and verify system performance within and beyond operating conditions.Equipped with measuring equipment, the dynamometer consistently  plots key parameters including Torque, RPM, and sound. These measurements provide critical insights into the performance characteristics of the examined gearbox. By capturing and analyzing this data, we can identify any potential issues, optimize performance, and ensure that the gearboxes meet the highest quality standards for  the medical device manufacturer.",
        video = '',
        image = '/assets/images/dyno-open.jpg',
        alt = "Dynamometer",
        teamMembers =  "Grant Peret, Jeff Nelson, Bob Peret",
        client = "Deka",
        timespan = "2021-2022",
        hardware = [
            {
                name: "Motor Controller",
                model: "Parker"
            },
            {
                name: "Allen Bradley Brushless DC Motor",
                model: "VPL-A1001M"
            },
            {
                name: "Magnetic Particle Brake",
                model: "PLB-200"
            }
        ];


        return(
            <Fragment> 
                <IndividualProject title={title} pageTitle={pageTitle} description={description} image={image} alt={alt} teamMembers={teamMembers} client={client} timespan={timespan} hardware={hardware} video={video}/>
            </Fragment>
        )
    }
}

export default Dyno;