import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/Header";
import FooterTwo from "../component/FooterTwo";
import Helmet from "../component/Helmet";
import { Link } from 'react-router-dom';

const list = [
    {
        title: 'Mechanical Design',
        headline: 'Battle tested design.',
        image: '/assets/images/service/solidworks.jpg',
        alt: 'Mechanical CAD design',
        description: 'Our expertise with CAD technologies allows us to model and prototype your idea with precision. In order to guarantee that our design will be capable of doing the job at hand, we run additional tests such as thermal, stress, and fluid dynamics once the modeling phase is complete.'
    },
    // {
    //     title: 'Web Development',
    //     headline: 'Unlock Success with Web Development Pioneers.',
    //     image: '/assets/images/service/webDevelopment.jpg',
    //     alt: 'Website Development',
    //     url: 'services/web-development',
    //     description: "Our expertise lies in web development, specializing in ECommerce platforms such as Shopify and WooCommerce. With a deep understanding of these platforms, we create seamless online shopping experiences that drive sales and customer engagement. Additionally, we excel in implementing and tracking analytics using Google Tag Manager, allowing businesses to gain valuable insights into their website's performance and make data-driven decisions for growth and optimization."
    // },
    {
        title: 'Embedded Software',
        headline: 'Software you can trust.',
        image: '/assets/images/service/embedded.jpg',
        alt: 'Prototype of a circuit board',
        description: "One of Raven Labs' specialties is developing software that manages physical devices. Our team of developers has spent over three decades perfecting embedded software for use in healthcare, robotics, and the Internet of Things. Our test-driven development process ensures rapid iteration, optimization, and the delivery of high-quality software that can be used with confidence on any hardware."
    },
    {
        title: 'Front End Development',
        headline: 'Clean interfaces and user-friendly layouts.',
        image: '/assets/images/service/python.jpg',
        alt: 'Python code',
        description: "For medical and research equipment, Raven Labs has created console apps in WPF (C#), DearPyGUI (Python), Tkinter (Python), and QT (Python), in addition to a small number of web-based applications. Raven Labs can assist with the creation and development of your next application by utilizing the services of industry-leading user experience designers."
    },
    {
        title: 'Prototyping',
        headline: 'The ability to materialize any vision.',
        image: '/assets/images/service/printers.jpg',
        alt: 'FDM 3D printers',
        description: "Raven Labs utilizes various manufacturing processes to bring digital concepts to life. We are proud to  prototype using in house capabilities including 3D Printing, CNC Machining, Laser Cutting, Thermoforming, and Welding. When our in house resources aren't a good fit, we realize success by working directly with our partners across New England. These services include, but are not limited to;  5-Axis CNC equipment, Injection Molding, Rotomolding, EDM Machining, Laser Welding and Engraving."
    },
]


const Services = () => {
    return (
        <div>
            <Helmet pageTitle="Services" />
            <Header homeLink="/"/>

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title textWhite">Services</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}


            {list.map((value , index) => (
                <div key={index}>
                    <div id="about" className="fix">
                        <div className="ptb--120  bg_color--1">
                            <div className="container">
                                <div className="row row--35 align-items-center">
                                    <div className={`col-lg-7 order-lg-${index%2 ? 2: 1} order-2`}>
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h2 className="title">{value.title}</h2>
                                                <p className="description"><b>{value.headline} </b>
                                                {value.description}
                                                </p>
                                            </div>
                                            { value.url &&
                                            <div className="pt--20">
                                                <Link className=" rn-button-style--2" to={value.url} ><span>Learn More</span></Link>
                                            </div> }
                                        </div>
                                    </div>
                                    <div className={`col-lg-5 order-lg-${index%2 ? 1: 2} order-1`}>
                                        <div className="thumbnail">
                                            <img className="w-110" src={value.image} alt={value.alt}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            
            
            {/* End Mobility Bus accelerator area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>
    )
}

export default Services;