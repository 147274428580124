import React, { Component , Fragment } from "react";
import IndividualProject from "../component/IndividualProject"


class CatAlley extends Component{
    render(){
        let title = 'Cat Alley',
        pageTitle = "Manchester's Cat Alley",
        description = 'Located in the heart of Manchester New Hampshire, Raven Labs  designed and fabricated the overhanging entrance to Cat Alley. This project was completed without charge by Raven Labs employees  with the intention of  improving the city we love. Working with the City of Manchester,  and local community members, we are proud to have brought this public art to life.',
        video = '/assets/images/digital-sign.mov',
        image = '',
        alt = "Cat Alley Manchester, NH",
        teamMembers =  "Grant Peret",
        client = "City of Manchester",
        timespan = "Summer 2020",
        hardware = [
            
        ];


        return(
            <Fragment> 
                <IndividualProject title={title} pageTitle={pageTitle} description={description} image={image} alt={alt} teamMembers={teamMembers} client={client} timespan={timespan} hardware={hardware} video={video}/>
            </Fragment>
        )
    }
}

export default CatAlley;