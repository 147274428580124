import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/Header";
import Footer from "../component/Footer";
import Helmet from "../component/Helmet";
import {Link} from "react-router-dom";


const PortfolioListContent = [
    {
        image: 'image-4',
        category: 'Assembly Line Automation',
        title: 'Dynamometer',
        url: '/projects/dyno'
    },
    {
        image: 'image-1',
        category: 'Mechanical Engineering',
        title: 'Mobility Bus',
        url: '/projects/mobility-bus'
    },
    {
        image: 'image-3',
        category: 'Mechanical Engineering',
        title: "Manchester's Cat Alley",
        url: '/projects/cat-alley'
    },
    {
        image: 'image-2',
        category: 'Internet Of Things',
        title: 'Asset Tracking',
        url: '/projects/helium'
    },
    {
        image: 'image-5',
        category: 'Scientific Instruments',
        title: 'Motorized Monochromator',
        url: '/projects/monochromator'
        
    },
    {
        image: 'image-6',
        category: 'Pharmacy Automation',
        title: 'Remote Verification',
        url: '/projects/remote-verification'
    },
]


const Projects = () => {
    return (
        <div>
            <Helmet pageTitle="Projects" />
            <Header homeLink="/"/>

            {/* Start Breadcrumb Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"  data-black-overlay="7">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title textWhite">Latest Projects</h2>
                                {/* <p>Ranging from medical devices to custom RV's, Raven Lab's has experience in it all.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrumb Area */}
            
            {/* Start Portfolio Area */}
            <div className="portfolio-area ptb--60 bg_color--1">
                <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <p>Because of the nature of our work, many of our projects are under NDA, but here are some of the most current ones we can talk about.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {PortfolioListContent.map((value , index) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                                    <div className="portfolio text-center mt--40">
                                        <div className="thumbnail-inner">
                                            <div className={`thumbnail ${value.image}`}></div>
                                            <div className={`bg-blr-image ${value.image}`}></div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <p>{value.category}</p>
                                                { value.url ? <h4><Link to={value.url}>{value.title}</Link></h4> : <h4>{value.title}</h4> }
                                                { value.url &&
                                                <div className="portfolio-button">
                                                    <Link className="rn-btn" to={value.url}>View Details</Link>
                                                </div>
                                            }
                                            </div>
                                        </div>
                                        { value.url &&
                                            <Link className="link-overlay" to={value.url}></Link>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            <Footer />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>
    )
}

export default Projects;
