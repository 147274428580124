import React, { Component , Fragment } from "react";
import IndividualProject from "../component/IndividualProject"


class VirtualVerification extends Component{
    render(){
        let title = 'Remote Verification',
        pageTitle = "Remote Verification",
        description = 'Working with a Fortune 5 company, Raven Labs was key to the design, manufacturing, and testing of a device which facilitates prescription monitoring nationwide. The device is a virtual verification system which allows pharmacists to verify prescriptions remotely. Raven Labs designed a custom enclosure, injection molded pill trays, and created an automated testing environment to ensure all units shipped met the highest quality standards. The device is currently deployed in 6,000+ pharmacies nationwide.',
        video = '/assets/images/PillTrayAnimation.mp4',
        image = '',
        alt = "3D pill tray animation",
        teamMembers =  "Grant Peret, Jeff Nelson",
        client = "Fortune 5 Company",
        timespan = "2020-present",
        hardware = [
            
        ];


        return(
            <Fragment> 
                <IndividualProject title={title} pageTitle={pageTitle} description={description} image={image} alt={alt} teamMembers={teamMembers} client={client} timespan={timespan} hardware={hardware} video={video}/>
            </Fragment>
        )
    }
}

export default VirtualVerification;