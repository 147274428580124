import React, { Component , Fragment } from "react";
import IndividualProject from "../component/IndividualProject"


class Mobility extends Component{
    render(){
        let title = 'Mobius Mobility',
        pageTitle = "Mobility Bus",
        description = 'The mechanical team at Raven Labs collaborated with Mobius Mobility to fulfill a unprecedented idea. Tour a mobility bus  capable of distributing iBOT devices & training resources to veterans across the country, A radical rethinking was necessary. The renovation includes a large slide-out incorporating a human elevator and aluminum deployable stairs. The inside was given a complete facelift, it now houses a service center,  classroom for iBOT training, and a storage facility for five iBOTs. In the summer of 2020, the Mobility Bus conducted its maiden cross-country mission.',
        image = '/assets/images/portfolio/mobility-bus-2.webp',
        video = '', 
        alt = "Mobius Mobility",
        teamMembers =  "Grant Peret",
        client = "Deka Research",
        timespan = "Oct 2020 - May 2021",
        hardware = [
            {
                name: "Elevator Leadscrew",
                model: "Nook PowerAC 1-1/4"
            },
            {
                name: "Elevator Rails and Bearings",
                model: "Hevi-Rail HVB-063"
            },
            {
                name: "Motor and Controller Equipment",
                model: "Rockwell Automation"
            },
            {
                name: "Safety Brake",
                model: "Sepac MSEB"
            },
            {
                name: "Slideout Support Legs",
                model: "Thomson Electrak HD"
            }
        ];


        return(
            <Fragment> 
                <IndividualProject title={title} pageTitle={pageTitle} description={description} image={image} alt={alt} teamMembers={teamMembers} client={client} timespan={timespan} hardware={hardware} video={video}/>
            </Fragment>
        )
    }
}

export default Mobility;