import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/mobius-logo.webp" alt="Mobius Mobility Company Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/nowi-logo.avif" alt="NoWi LLC, Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/optometrics-logo.png" alt="Optometrics Company Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/onno-logo-black.png" alt="ONNO Company Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/bridge-appliances-logo.webp" alt="Bridge Appliances Company Logo"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/lantheus-logo.svg" alt="Lantheus Company Logo"/>
                    </li>
                    {/* <li>
                        <img src="/assets/images/brand/brand-02.png" alt="Logo Images"/>
                    </li> */}
                    {/* <li>
                        <img src="/assets/images/brand/brand-03.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Logo Images"/>
                    </li> */}
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;