import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
    render() {
        // Destructure props for easier access
        const {
            pageTitle,
            metaDescription = "Engineering firm located in Manchester, NH that offers Mechanical, Software, UX design services", // Default description
            metaKeywords = [], // Empty array as default
            canonicalUrl = '',
            ogTitle = '',
            ogType = 'website',
            ogImage = '',
            ogUrl = '',
            twitterCard = 'summary_large_image',
            twitterTitle = '',
            twitterDescription = '',
            twitterImage = ''
        } = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{pageTitle} | Raven Labs</title>
                    <meta name="description" content={metaDescription} />
                    <meta name="keywords" content={metaKeywords.join(',')} />
                    {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
                    {/* Open Graph / Facebook Meta */}
                    <meta property="og:title" content={ogTitle || pageTitle} />
                    <meta property="og:type" content={ogType} />
                    <meta property="og:image" content={ogImage} />
                    <meta property="og:url" content={ogUrl || canonicalUrl} />
                    {/* Twitter Card Meta */}
                    <meta name="twitter:card" content={twitterCard} />
                    <meta name="twitter:title" content={twitterTitle || pageTitle} />
                    <meta name="twitter:description" content={twitterDescription || metaDescription} />
                    <meta name="twitter:image" content={twitterImage || ogImage} />
                </Helmet>
            </React.Fragment>
        );
    }
}

export default PageHelmet;
