import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>We were blown away by the prototypes that Raven Labs provided. They saved us months in development time and we're excited to work with them again.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Charles Fayal</span> - CEO, NOWi Sensors</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Working with Raven Labs has been a great experience.  Our projects were completed fast and the communication could not have been better.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Jack Kanefield</span> - CEO, ONNO</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>When everyone else came to the table with costly and time consuming solutions, Grant personally worked with me to come up with creative ways to keep me on track and under budget.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Dan Finnegan</span> - Infraspect</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/Nowi.avif" alt="NoWi company logo"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/onno_logo_black.png" alt="ONNO company logo"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/Infraspect_logo.png" alt="Infraspect company logo"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;