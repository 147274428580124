import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import ServiceList from "../component/ServiceList";
import Header from "../component/Header";
import FooterTwo from "../component/FooterTwo";
import CallAction from "../component/CallAction";
import BrandTwo from "../component/BrandTwo";
import Testimonial from "../component/Testimonial";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/Helmet";

class Home extends Component {
  componentDidMount() {
    window.analytics.page("Home");

    window.analytics.track("f4ca124298", {
      name: "Michael Bolton",
      email: "mbolton@initech.com",
    });
  }

  render() {
    return (
      <Fragment>
        <Helmet pageTitle="Home" />
        <Header />
        {/* Start Slider Area   */}

        <div id="home" className="fix">
          <div className="slider-wrapper">
            {/* Start Single Slide */}

            <div
              className="slide slide-style-2 d-flex align-items-center justify-content-center bg_image bg_image--23"
              data-black-overlay="8"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inner text-left">
                      <h1 className="title">
                        Small Team Feel. <br></br> Big Company Innovation.
                      </h1>

                      <p className="description">
                        A boutique agency specializing in Software, Mechanical,
                        and Automation Engineering
                      </p>
                      <div className="slide-btn">
                        <a
                          className="rn-button-style--2 btn-solid"
                          href="/contact"
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Client Logo Area */}

        <div className="rn-brand-area brand-separation bg_color--1">
          <div className="container brand-container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>

        {/* End Client Logo Area */}

        {/* Start Service Area  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                  <h2 className="title">Our Services</h2>
                  <p>
                    A handcrafted team of skilled professionals with a combined
                    50 years of professional experience
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Testimonial Area */}
        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default Home;
