import React, { Component } from "react";
import PageHelmet from "../component/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/Header";
import Footer from "../component/FooterTwo";
import Modal from "react-bootstrap/Modal"
import { Storage } from "aws-amplify";
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import HubspotResumeForm from "./HubspotResumeForm";


Storage.configure({ track: true, level: "public" });


class IndividualJob extends Component{
    constructor(props){
        super(props)
        this.state = {
            selectedFile: null,
            show: false
        };
        this.handleFileInput = this.handleFileInput.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }
    
    handleClose = () => {
        this.setState({ 
            show: false,
            selectedFile: null
         });
    }
    handleShow = () => {
        this.setState({ show: true });
    }

    handleFileInput = (e) => {
        this.setState({
            selectedFile: e.target.files[0]
          });
          console.log(e.target.files[0]);
    }

    handleUpload = () => {
        Storage.put(this.state.selectedFile.name, this.state.selectedFile, {
            contentType: "image/png"
            })
            .then(result => console.log(result))
            .catch(err => console.log(err));

        const email = {
            full_name: "Jeff",
        }

        emailjs
            .send(
                'resume_upload', 
                'template_yveycn5', 
                email, 
                'jL1sj-VsQ2YChI578'
            )
            .then((result) => {
                console.log("Email " + result.text);
                }, 
                (error) => {
                    console.log(error.text);
                }
            );
        this.handleShow();
        
    }


    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle={this.props.pageTitle} />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title textWhite">Careers</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                <div className="fix">
                    <div className="pt--120 pb--30 bg_color--1">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5 order-1 order-lg-1">
                                    <div className="thumbnail">
                                        <img className="w-110" src={this.props.image} alt={this.props.alt}/>
                                    </div>
                                </div>
                                <div className="col-lg-7 order-2 order-lg-2" >
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{this.props.title}</h2>
                                            <p className="description">{this.props.description}</p>
                                            <ul>
                                                {this.props.skills.map((value , index) => (
                                                    <div key={index}>
                                                        <li>
                                                            <div>
                                                                {value}
                                                            </div>
                                                        </li>
                                                    </div>               
                                                ))}
                                           </ul>
                                            <p>If you think you would be a good candidate. Upload your resume below.</p>
                                        </div>

                                        <div className="ptb--30">
                                            <HubspotResumeForm/>
                                        </div>
                                        

                                            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Upload Successful</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Thank you for uploading your resume and we look forward to reviewing it. A team member from Raven Labs will reach out to you shortly.</Modal.Body>
                    <Modal.Footer>
                    <Link className=" rn-button-style--2" to='/' ><span>Done</span></Link>
                    {/* <Button variant="primary" onClick={this.handleClose}>
                        Done
                    </Button> */}
                    </Modal.Footer>
                </Modal>



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default IndividualJob;