import React, { Component } from "react";
import { FiRadio , FiLayers , FiServer , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiRadio />,
        title: 'Hotspot Deployment',
        description: 'No Coverage? No Problem. We can help you deploy your own hotspot.'
    },
    {
        icon: <FiLayers />,
        title: 'Product Design',
        description: 'Our design team is well-equipped to handle the one-of-a-kind difficulties involved in creating cutting-edge IoT solutions.'
    },
    {
        icon: <FiMonitor />,
        title: 'Software Development',
        description: 'Our full stack development team has extensive expertise in creating reliable, scalable, and secure software and firmware.'
    },
    {
        icon: <FiServer />,
        title: 'Cloud Applications',
        description: 'Aggregate all your sensors data to make real time informed decisions'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Helium',
        description = 'The Helium Network has made it much simpler and cheaper than more conventional connectivity solutions to monitor, configure, and even control local and remote devices.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/contact"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
