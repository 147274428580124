import React from 'react';
import Helmet from "../component/Helmet";
import ScrollToTop from 'react-scroll-up';
import ContactOne from '../component/ContactOne';
import {FiChevronUp } from "react-icons/fi";
import Header from "../component/Header";
import FooterTwo from "../component/FooterTwo";
import ServiceTwo from '../component/ServiceTwo';


const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--24',
        category: 'We are Builders for',
        title: 'The Peoples Network.',
        description: '',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    },
   
]

const HeliumLanding = () => {
    return (
        <>
            <Helmet pageTitle="Helium" />
            <Header />
            
            {/* Start Slider Area   */}
            <div className="slider-wrapper">
                <div className="slider-activation">
                    {SlideList.map((value , index) => (
                        <div className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="7">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* End Slider Area   */}


            {/* Start About Area  */}
            <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/IoT.jpg" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">From Mind to Market</h2>
                                        <p>It can be difficult to undertake a large-scale IoT development project. Our award-winning product development teams are known for their dedication to timeliness, effectiveness, and scalability, as well as their superior technical abilities, in-depth product understanding, and crystal-clear communication.</p>
                                    </div>
                                    <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/about">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

            <div className="service-area ptb--80  bg_image bg_image--3">
                <div className="container">
                    <ServiceTwo />
                </div>
            </div>

             {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactOne />
                </div>
            </div>
            {/* End COntact Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <FooterTwo />
        </>
    )
}

export default HeliumLanding
