import React, { Component } from "react";
import PageHelmet from "../component/Helmet";
import { FiClock, FiUser } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/Header";
import Footer from "../component/Footer";

class IotBlogPart1 extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="Creating Your First IoT Device | Blog"
          metaDescription="Kickstart your IoT journey with our comprehensive guide on building a cost-effective IoT asset tracker using LoRaWAN. This series covers device configuration, cloud connectivity, and data visualization."
          metaKeywords={[
            "IoT",
            "LoRaWAN",
            "Helium Network",
            "Asset Tracker",
            "DIY IoT",
            "CubeCell",
            "ChirpStack",
            "LNS",
            "GPS Tracking",
            "Arduino",
            "Heltec Automation",
          ]}
          canonicalUrl="https://www.ravenlabsnh.com/blog/iot-asset-tracker-part-1"
          ogTitle="Creating Your First IoT Device"
          ogType="article"
          ogImage="https://www.ravenlabsnh.com/assets/images/blog/CubeCellFinalDevice.webp"
          ogUrl="https://www.ravenlabsnh.com/blog/iot-asset-tracker-part-1"
          twitterCard="summary_large_image"
          twitterTitle="Creating Your First IoT Device | Blog"
          twitterDescription="Join us in our IoT series to build an IoT asset tracker with LoRaWAN for under $30. From device setup to cloud integration and UI development, we've got you covered."
          twitterImage="https://www.ravenlabsnh.com/assets/images/blog/CubeCellFinalDevice.webp"
        />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--28"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title textWhite">
                    Build an IoT Asset Tracker <br />
                  </h2>
                  <h3 className="title textWhite">
                    {" "}
                    Step-by-Step Guide: Part 1
                  </h3>

                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      November, 2023
                    </li>
                    <li>
                      <FiUser />
                      Jeff Nelson
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--80 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <h3>Setting the Stage</h3>
                    <p>
                      Welcome to the first installment of our exciting IoT
                      series where we will guide you through creating a holistic
                      IoT application. By the end of this series, you'll have
                      built your own IoT asset tracker for under $30! Let’s dive
                      into the exciting world of IoT and explore why the LoRaWAN
                      network, among others, stands out as a particularly
                      compelling choice for our application.
                    </p>
                    <p>Our journey will unfold over three distinct phases:</p>

                    <ul className="list-style">
                      <li>
                        Configuring the device and establishing communication
                        over the LoRaWAN network.
                      </li>
                      <li>Connecting the IoT device to the cloud.</li>
                      <li>
                        Crafting a user-friendly front-end application to
                        visualize the data.
                      </li>
                    </ul>
                    <div className="thumbnail mt--20">
                      <img
                        className="no-shadow"
                        src="/assets/images/blog/CubeCellRawParts.webp"
                        alt="CubeCell HTC-AB02S w/ antennas"
                      />
                    </div>
                    <p>
                      A heads-up for our readers: a basic grasp of programming
                      and associated tools is not strictly necessary, but will
                      come handy to follow this tutorial seamlessly.
                    </p>
                    <p>
                      LoRaWAN networks are proliferating, with notable ones
                      being The Things Network, Senet, Actility, and Helium. For
                      this tutorial, we'll work with the Helium Network. Helium
                      is pioneering a decentralized and crowd-sourced approach
                      to LoRaWAN networking. It is not only the largest of its
                      kind but also incredibly economical. Its vibrant community
                      offers a treasure trove of resources to delve into.
                    </p>

                    <h3>Part 1: Configuring your IoT device</h3>

                    <div className="blog-single-list-wrapper-left d-flex flex-wrap">
                      <div className="thumbnail">
                        <img
                          className="w-100 mt-60"
                          src="/assets/images/blog/LoRa.webp"
                          alt="LoRa Frequencies"
                        />
                        <span>
                          LoRa overview of the primary frequencies used in
                          different regions{" "}
                        </span>
                      </div>
                      <div className="content">
                        <h4>Setup - Things You'll Need</h4>

                        <ul className="list-style">
                          <li>
                            <a href="https://heltec.org/project/htcc-ab02s/">
                              CubeCell HTCC-AB02S board
                            </a>
                          </li>
                          <li>GPS Antenna (optional)</li>
                          <li>3.3V battery (or USB battery)</li>
                          <li>
                            An account on{" "}
                            <a href="https://console.helium-iot.xyz/front/">
                              Helium-IOT
                            </a>
                          </li>
                          <li>
                            <a href="https://www.arduino.cc/en/software">
                              Arduino IDE
                            </a>
                          </li>
                        </ul>
                        <p>
                          {" "}
                          Our pick for this tutorial is the Cube Cell HTCC-AB02S
                          from Heltec Automation. Retailing at a modest $25.80,
                          this board is Arduino-compatible and has integrated
                          LoRa and GPS features. When purchasing make sure to
                          select the appropriate frequency for your geographical
                          location. For our U.S.-based readers, you'll work with
                          a frequency range of 902.2-927.5 MHz. For other
                          regions, please refer to the frequency table provided.
                        </p>
                      </div>
                    </div>

                    <p>
                      We will use the Arduino Studio for our code editor. You
                      can download the software here. The code we will use was
                      forked from Jas-Williams'{" "}
                      <a href="https://github.com/jas-williams/CubeCell-Helium-Mapper">
                        Github repo
                      </a>
                      , a prominent figure in the Helium community. You can get
                      the forked version of the code we will be using{" "}
                      <a href="https://github.com/RavenLabsNH/Helium/blob/main/Projects/CubeCell/HTCC-AB02S/Mapper/Mapper.ino">
                        here
                      </a>
                      .
                    </p>

                    <h4>Setting Up the Chirpstack LNS</h4>

                    <p className="mt--40">
                      To get started, you'll first need to establish an account
                      with a LoRaWAN Network Service (LNS). For our tutorial,
                      we're choosing the Helium-IoT LNS, which uses Chirpstack,
                      an open-source LNS. With the Helium-IoT LNS, each new user
                      receives 500 free packets, making it perfect to try out
                      for this tutorial. The service operates on a pay-as-you-go
                      basis, so there are no monthly fees. The cost structure is
                      attractive, with 1 DC priced at just $0.00005. That
                      translates to just $.05 for every 1,000 packets sent.
                    </p>

                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="content mt--40">
                        <p>
                          Once you've set up an account on Helium-IoT, the first
                          step is to create a Device Profile, a configuration
                          template that provides device-specific parameters and
                          capabilities. To create a Device Profile for the
                          CubeCell board, perform the following steps:
                        </p>
                        <ul className="list-style mt--40">
                          <li>
                            Navigate to the Device Profile section. In the
                            left-hand menu, under the “Tenant” category, find
                            and click on "Device Profile".
                          </li>
                          <li>
                            Proceed by selecting the “Add device Profile” option
                            in the top right section of the page.
                          </li>
                          <li>
                            Next is to name the device. Since our focus is on
                            the CubeCell board, name it "CubeCell AB02S". For
                            additional context, use the description: “CubeCell
                            HTCC-AB02S development board with GPS".
                          </li>
                          <li>
                            It's important to set your device to the correct
                            geographic region. Refer to the table above provided
                            for your specific region. For those in the United
                            States, the choice is "US915".{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="thumbnail">
                        <img
                          className="w-170"
                          src="/assets/images/blog/ChirpstackCreateDevice.webp"
                          alt="Chirpstack Create Device"
                        />
                      </div>
                    </div>

                    <div className="blog-single-list-wrapper-left d-flex flex-wrap">
                      <div className="thumbnail">
                        <img
                          className="w-100"
                          src="/assets/images/blog/ChripstackCreateApplication.webp"
                          alt="Chirpstack - Create Application"
                        />
                      </div>
                      <div className="content">
                        <p>
                          With the Device Profile set up correctly in
                          Chirpstack, our next step is to set up an Application.
                          In the context of LNS, an "Application" is essentially
                          a collection of devices unified by shared encoding,
                          decoding, and processing functionalities. This allows
                          for more organized management of devices based on
                          their specific use case and end application. Our
                          application we are about to create is going to be an
                          Asset Tracker.
                        </p>
                        <h5>Set up the Application:</h5>
                        <ul className="list-style mt--40">
                          <li>
                            Navigate to the Application section found under the
                            "Tenant" category in the left menu.
                          </li>
                          <li>Select “Add Application".</li>
                          <li>
                            Name it appropriately, for instance, "Asset
                            Tracker".
                          </li>
                          <li>
                            Provide a brief description, such as "Our First
                            Asset Tracking Application".{" "}
                          </li>
                          <li>
                            Create the new application by clicking "Submit".{" "}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="content mt--40">
                        <h5>Register Your Device:</h5>

                        <p>
                          Now that we have the application created, we need to
                          link the devices that are a part of this application.
                          Lets now create a device with the device profile we
                          just created.
                        </p>
                        <ul className="list-style mt--40">
                          <li>
                            In the newly created application, select “Add
                            Device”.
                          </li>
                          <li>
                            Name your device, perhaps “CubeCell AB0S2”, and
                            consider adding a description.
                          </li>
                          <li>
                            To generate a Device EUI, simply click the
                            corresponding button. This EUI is crucial as it
                            dictates how the LoRaWAN network routes incoming
                            packets from devices on the Helium Network, like our
                            Asset Tracker.{" "}
                          </li>
                        </ul>

                        <h5>Link to Device Profile:</h5>

                        <p>
                          Now that this new device created, lets link in to our
                          application.
                        </p>

                        <ul className="list-style mt--40">
                          <li>
                            From the available options, choose the Device
                            Profile we established earlier.
                          </li>
                          <li>Confirm all the details by clicking "Submit".</li>
                        </ul>
                      </div>
                      <div className="thumbnail">
                        <img
                          className="w-170"
                          src="/assets/images/blog/ChirpstackLinkDevice.webp"
                          alt="Chirpstack Create Device"
                        />
                      </div>
                    </div>

                    <p>
                      For now, we've concluded the console setup phase. However,
                      don’t close out of this page yet. We'll soon need the
                      Device EUI, and after device configuration, this is where
                      you'll observe incoming data packets.
                    </p>

                    <h4>
                      Setting Up the CubeCell Development Board in Arduino IDE
                    </h4>

                    <p>
                      In this section, we are going to get the device configured
                      to broadcast it's GPS coordinates over the LoRaWAN
                      network. Start by launching the Arduino IDE editor that
                      you just installed. Next is to install the CubeCell
                      package for Arduino. To do this:
                    </p>
                    <ul className="list-style mt--40">
                      <li>
                        Go to <strong>File > Preferences</strong>.
                      </li>
                      <li>
                        In the window that appears, scroll to the "Additional
                        Boards Manager URL" section near the bottom.
                      </li>
                      <li>
                        Enter the following URL:{" "}
                        <strong>
                          http://resource.heltec.cn/download/package_CubeCell_index.json
                        </strong>
                        .{" "}
                      </li>
                    </ul>
                    <div className="thumbnail pb--60">
                      <img
                        src="/assets/images/blog/ArduinoBoardManager.webp"
                        alt="CubeCell HTC-AB02S w/ antennas"
                      />
                    </div>
                    <p>
                      With the board library location set in Arduino's
                      preferences, we can now install the required CubeCell
                      Development Framework. Navigate your way to{" "}
                      <strong>Tools > Board > Boards Manager</strong>. Here,
                      search for "CubeCell" and install the most recent version
                      available.
                    </p>

                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="content mt--20">
                        <p>
                          After the CubeCell framework is installed, it's
                          essential to select your specific development board.
                          To do that, head over to{" "}
                          <strong>Tools > Board</strong> and pick the{" "}
                          <strong>"CubeCell - GPS (HTCC-AB02S)"</strong> from
                          the list.
                        </p>
                        <p>
                          With the board set up in the IDE, let's load and
                          configure our project. Open the GitHub project you
                          downloaded earlier. One vital configuration aspect to
                          ensure is the region setting. Under the Tools section
                          of the IDE, ensure that the LORAWAN_REGION matches
                          your specific region. For our example, it's
                          REGION_US915, but keep in mind that this might differ
                          based on where you are in the world.
                        </p>
                        <p>
                          Now, it's time to focus on another important aspect:
                          the Device EUI, App EUI (this is labeled Join EUI in
                          the Helium-IoT console), and the App Key. Dive into
                          the code and locate the following lines near the top:
                        </p>
                        <pre>
                          <code>
                            {`/* OTAA para*/
uint8_t devEui[] = { 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00 };
uint8_t appEui[] = { 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00 };
uint8_t appKey[] = { 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00 };
`}
                          </code>
                        </pre>
                      </div>
                      <div className="thumbnail">
                        <img
                          className="w-170"
                          src="/assets/images/blog/ArduinoDeviceSettings.webp"
                          alt="Ardunio Device Settings for CubeCell"
                        />
                      </div>
                    </div>

                    <p>
                      You'll need to replace this with the Device EUI, Join EUI
                      (appEUI in the arduino code), and Application key from the
                      Helium-IoT console. To ensure you've copied it accurately
                      and kept to the format used in the code, click on the copy
                      icon next to each field in the console, and select “Hex
                      Array”.
                    </p>

                    <p>
                      {" "}
                      Now that we have our board configured and the code updated
                      with our unique IDs from ChirpStack, we can program our
                      board and try it out! Start by connecting the CubeCell dev
                      board to your PC with a USB cable. Next, make sure you've
                      selected the appropriate COM port in Arduino; if you're
                      uncertain, you can always check the Windows Device Manager
                      to locate the right port, assuming you are on windows.
                      Similar tools exist for both Mac and Linux. With all in
                      place, click the "Upload" button in Arduino. This will
                      compile your code and upload it to the CubeCell board. We
                      are now ready to test if we can send messages over the
                      Helium Network!
                    </p>

                    <p>
                      {" "}
                      Upon completing the upload, the CubeCell board's LED
                      screen should spring to life, displaying the message
                      "Joining". For successful communication, ensure you're in
                      an area with Helium IoT coverage (check coverage using
                      this <a href="https://explorer.moken.io/"> Map</a>). Once
                      the connection is established to the Helium Network, the
                      CubeCell board will momentarily flash "Joined".
                    </p>

                    <img
                      className="pb--60"
                      src="/assets/images/blog/ChirpStackDeviceConnected.webp"
                      alt="Successful IoT device connected to ChirpStack"
                    />

                    <p>
                      {" "}
                      Now, head back to the Helium-IoT device console and click
                      on your device in the application page. Navigate over to
                      the "LoRaWaN frames" tab. Here you should see the Join
                      Request come in, followed by another frame that says Join
                      Accept. This frame signals a successful connection to the
                      Helium IoT network. With this accomplishment, your IoT
                      device is now fully ready to send messages over the
                      network. But to leverage its full potential, especially
                      for location tracking, it must connect to GPS satellites.
                      While a simple approach might be to use a USB battery or
                      position the device near a window, we suggest going a step
                      further. How about 3D printing a protective case,
                      equipping it with an external battery, and testing the IoT
                      Asset Tracker in a real-world scenario? Let's dive into
                      that next!
                    </p>

                    <div className="blog-single-list-wrapper-left d-flex flex-wrap">
                      <div className="thumbnail">
                        <img
                          className="w-100 no-shadow"
                          src="/assets/images/blog/CubeCellFinalDevice.webp"
                          alt="CubeCell 3D printed Case"
                        />
                      </div>
                      <div className="content">
                        <p>
                          Luckily for this CubeCell device, a member of the
                          Helium community has designed a 3D printable case.
                          Here's the link to the free files available on
                          <a href="https://www.thingiverse.com/thing:4982572/files">
                            {" "}
                            Thingiverse
                          </a>
                          . While we are using a Prusa MK3S for our choice of
                          printer, most standard 3D printers should suffice.
                          Given our intention to deploy the device outdoors,
                          possibly during hot days, we chose PETG for its higher
                          heat resistance. However, if you're looking for an
                          alternative, PLA should serve well. As for powering
                          the device, we found some 3.3V batteries in our lab
                          that fit the bill. Another great feature of the case
                          is its designated section for a GPS Antenna,
                          significantly enhancing signal acquisition speed. Once
                          the printing was done, we were quite pleased with the
                          outcome. Take a look at the final result!
                        </p>

                        <p>
                          In our upcoming post, we'll delve deeper into the
                          capabilities of this IoT device. We'll explore how to
                          decode the data in the Chirpstack console after it's
                          been relayed through the Helium Network and how to
                          seamlessly integrate it with AWS. Within AWS, we'll
                          discuss the intricacies of data flow, ensuring every
                          packet finds its way into a dedicated database. And
                          for the grand finale in our series, we'll harness this
                          data to craft a dynamic web application for its
                          display. Stay tuned!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default IotBlogPart1;
