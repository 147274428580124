import React, { Component ,Fragment } from "react";

const BlogContent = [
    {
        images: '/assets/images/portfolio/helium-tracker.webp',
        title: 'Creating your own Asset Tracker - Part 1',
        url: '/blog/iot-asset-tracker-part-1',
        category: 'IoT'
    },
    {
        images: '/assets/images/portfolio/ChirpStackDashboard.webp',
        title: 'Creating your own Asset Tracker - Part 2',
        url: '/blog/iot-asset-tracker-part-2',
        category: 'IoT'
    },
]

class BlogList extends Component{
    render() {
        const PostList = BlogContent.slice(0 , 6);
        return (
             <Fragment>
                 <div className="row">
                    {PostList.map((value , i ) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <a href={value.url}>
                                        <img className="w-100" src={`${value.images}`} alt="Blog Images"/>
                                    </a>
                                </div>
                                <div className="content">
                                    <p className="blogtype">{value.category}</p>
                                    <h4 className="title"><a href={value.url}>{value.title}</a></h4>
                                    <div className="blog-btn">
                                        <a className="rn-btn text-white" href={value.url}>Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                     ))}
                 </div>
             </Fragment>
        );
    }
}
export default BlogList;