// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';


// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

import Home from './pages/Home';
import Projects from './pages/Projects';
import Services from "./pages/Services";
import WebDevelopment from './pages/WebDevelopment';
import Helium from "./pages/Helium";
import About from "./pages/About";
import Career from "./pages/Career";
import Dyno from './pages/Dyno';
import JuniorMechanicaL from "./pages/JuniorMechanical";
import VirtualVerification from './pages/VirtualVerification';
import Monochromator from './pages/Monochromator';
import Fairing from './pages/Fairing';
import Contact from "./pages/Contact";
import error404 from "./pages/error404";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import ComingSoon from './pages/ComingSoon';
import MechanicalIntern from './pages/MechanicalIntern';
import SoftwareIntern from './pages/SoftwareIntern';
import Mobility from './pages/Mobility';
import CatAlley from './pages/CatAlley';
import Store from './pages/Store';
import Shirt from './pages/Shirt';
import PurchaseComplete from './pages/PurchaseComplete';
import Upload from './pages/Upload';
import Blog from './pages/Blog';
import IotBlogPart1 from './pages/IotBlogPart1';
import IotBlogPart2 from './pages/IotBlogPart2';
import Syringe from './pages/Syringe'

import { Amplify} from "aws-amplify";
import { Auth } from '@aws-amplify/auth';
import awsconfig from "./aws-exports";
import HeliumLanding from './pages/HeliumLanding';
Amplify.configure(awsconfig);
Auth.configure(awsconfig);


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/coming-soon`} component={ComingSoon}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/projects`} component={Projects}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/projects/mobility-bus`} component={Mobility}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/projects/cat-alley`} component={CatAlley}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/projects/helium`} component={Helium}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/projects/dyno`} component={Dyno}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/projects/syringe`} component={Syringe}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/projects/remote-verification`} component={VirtualVerification}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/projects/monochromator`} component={Monochromator}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/services`} component={Services}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/services/web-development`} component={WebDevelopment}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/services/fairing-integration`} component={Fairing}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/services/helium`} component={HeliumLanding}/>
                        
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/careers`} component={Career}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog/iot-asset-tracker-part-1`} component={IotBlogPart1}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog/iot-asset-tracker-part-2`} component={IotBlogPart2}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/store`} component={Store}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/store/tshirt`} component={Shirt}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/upload`} component={Upload}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/store/checkout/:full_name/:email_address/:phone_number/:address/:city/:state/:zip_code`} component={PurchaseComplete}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/careers/mechanical-job-1`} component={JuniorMechanicaL}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/careers/mechanical-job-2`} component={MechanicalIntern}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/careers/software-job-1`} component={SoftwareIntern}/>
                        
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.unregister();
