import React, { Component } from "react";
import Helmet from "../component/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/Header";
import Footer from "../component/Footer";


class Fairing extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
            <Helmet pageTitle="Fairing Integration" />
            <Header homeLink="/"/>

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="7">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title textWhite">Fairing Integration</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/webDevelopment2.avif" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>We specialize in seamlessly integrating your WordPress website and WooCommerce store with Fairing for Analytics. With our expertise in e-commerce solutions, we understand the importance of data-driven insights to enhance your online business. By connecting your WooCommerce store with Fairing for Analytics, you gain access to a powerful tool that helps you understand your customers better and make informed decisions to boost your sales and optimize your marketing strategies.</p>
                                                    <p>Work with us to unlock the true potential of your online presence. We bring together expertise in ECommerce, infrastructure, hosting environments, platforms, and attribution software. Our tailored solutions drive success in the digital landscape, delivering exceptional user experiences and empowering data-backed decision-making.</p>
                                                    <h4 className="title">Technologies</h4>
                                                    <ul className="liststyle">
                                                        <li>Platforms: Wordpress, WooCommerce, Shopify, BigCommerce</li>
                                                        <li>Integrations: GTM, GA4, Fairing</li>
                                                        <li>Hosting Providers: AWS, Google Cloud, Microsoft Azure, Kinsta, Netlify </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default Fairing;